import { Component, OnInit } from '@angular/core';
import { LoaderService } from '../../../core/services/loader.service';

@Component({
  selector: 'app-rp-loader',
  templateUrl: './rp-loader.component.html',
  styleUrls: ['./rp-loader.component.scss'],
})
export class RpLoaderComponent implements OnInit {
  loading: boolean = false;

  constructor(public loaderService: LoaderService) {}

  ngOnInit(): void {
    /*  this.loaderService.loading$.subscribe(loading => {
       this.loading = loading;
     }); */
  }
}
