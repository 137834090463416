<div class="modal-content">
    <div class="modal-header bg-primary text-white">
        <h5 class="modal-title">
            {{ pageData.title?.toUpperCase() !== 'IOT_DEVICE_BULK_UPDATE' ? 'Bulk Upload' : 'Bulk Update' }}
          </h5>
          
        <button type="button" class="btn-close text-white" aria-label="Close" (click)="dismissModal()"></button>
    </div>
    <div class="modal-body">
        <form class="form-horizontal">
            <div class="mb-3">
                <button 
                type="button" 
                class="btn btn-light btn-label waves-light"  
                (click)="handleDownloadTemplate()">
                <i class="mdi mdi-download label-icon"></i> Download Template
              </button>
              
            </div>
            <label>Upload Template</label>
            <kendo-upload [saveUrl]="uploadSaveUrl" (select)="handleFileSelected($event)" [removeUrl]="uploadRemoveUrl"
                [batch]="true" (success)="onUploadSuccess($event)" (error)="onUploadError($event)"
                [restrictions]="myRestrictions" [multiple]="false"   accept=".doc,.docx,.xls,.xlsx">
            </kendo-upload>

        </form>
    </div>
    <div class="modal-footer">
        <!-- <button type="button" class="btn btn-secondary waves-effect" data-bs-dismiss="modal" >Close</button> -->
        <button type="button" class="btn btn-primary waves-effect waves-light" [disabled]="!file"
            (click)="uploadFile()">  {{ pageData.title?.toUpperCase() !== 'IOT_DEVICE_BULK_UPDATE' ? 'Upload' : 'Update' }}</button>
    </div>
</div>