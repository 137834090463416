import { AppConfigData } from '../../core/config/appConfigData';

export const Templates = {
  IOT_DEVICE: {
    template_name: 'iotDevices.xlsx',
    id: 'IOT_DEVICE',
    url: AppConfigData.iotBulkUpload,
    requiredProjectId: false,
  },
  IOT_DEVICE_BULK_UPDATE: {
    template_name: 'iotDevices_Bulk_Update.xlsx',
    id: 'IOT_DEVICE_BULK_UPDATE',
    url: AppConfigData.iotDeviceBulkUpdate,
    requiredProjectId: false,
  },
  
} as const;

export const table_pageSize = {
  pageSizes: [5, 10, 20, 50, 100, 200, 500, 750, 1000],
}

export const slop_position = [
  { id: 1, name: '1' },
  { id: 2, name: '2' },
  { id: 3, name: '3' },

]

export const title_List = [
  { id: '1', name: 'Mr' },
  { id: '2', name: 'Mrs' },
  { id: '3', name: 'Miss' },

]

export const utilitiesArray = ['Water', 'Electricity', 'Gas']

export const colorsList = ['#FADF89', '#F9AF58', '#F37E53', '#E96360', '#DB6FA0'];

export const ConsumptioncolorsList = ['#E96360', '#DB6FA0', '#AD8AC0', '#6480BC', '#44C8F5'];

export const ConsumptionMinMax = [{ id: 1, name: 'Max' }, { id: 2, name: 'Min' }]

export const monthsArray = [
  'Current Month',
  'Last Month',
  'Last 3 Months',
  'Last 6 Months',
  'Custom Range',
];




