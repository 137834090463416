import { environment } from '../../../environments/environment';

export class AppConfigData {
  //ConfigData
  static configUrl: string =
    environment.ripple_iot_auth_baseUrl + 'configuration/get-config-data';

  //Auth
  static loginUrl: string =
    environment.ripple_iot_auth_baseUrl + 'account/login';
  static logOutUrl: string =
    environment.ripple_iot_auth_baseUrl + 'account/logout';
  static sendOtpUrl: string =
    environment.ripple_iot_auth_baseUrl + 'account/send-otp';
  static forgotPwdUrl: string =
    environment.ripple_iot_auth_baseUrl + 'account/forgot-password';
  static resetPwdUrl: string =
    environment.ripple_iot_auth_baseUrl + 'account/reset-password';
  static getProfileUrl: string =
    environment.ripple_iot_auth_baseUrl + 'account/user-profile';
  static updateProfileUrl: string =
    environment.ripple_iot_auth_baseUrl + 'account/edit-profile';
  static updatePwdUrl: string =
    environment.ripple_iot_auth_baseUrl + 'account/change-password';
  static refreshToken: string =
    environment.ripple_iot_auth_baseUrl + 'account/refresh-token';    
	static mewPasswordUrl: string = environment.ripple_iot_auth_baseUrl + 'account/set-new-password';  
	static pwdRegExp: string = environment.ripple_metering_baseurl + 'account/get-password-pattern';


  //BulkUploads
	static iotBulkUpload: string = environment.ripple_iot_auth_baseUrl + 'Device/import-iot-device-template';
  static iotDeviceBulkUpdate: string = environment.ripple_iot_auth_baseUrl + 'Device/update-iot-devices-from-excel'; 


  
	//Templates
	static getTemplatesUrl: string = environment.ripple_iot_auth_baseUrl + 'Device/generate-iot-device-template';
  static getExportDeviceUrl: string = environment.ripple_iot_auth_baseUrl + 'Device/export-iot-devices-excel';


  //Masters
  static getMastersUrl: string =
    environment.ripple_iot_auth_baseUrl + 'master/get-all-masters';
  static createMastersUrl: string =
    environment.ripple_iot_auth_baseUrl + 'master/create-master';
  static updateMastersUrl: string =
    environment.ripple_iot_auth_baseUrl + 'master/edit-master/';
  static deleteMastersUrl: string =
    environment.ripple_iot_auth_baseUrl + 'master/delete-master/';

  //Suppliers
  static getSuppliersUrl: string =
    environment.ripple_iot_auth_baseUrl + 'supplier/get-all-suppliers';
  static createSuppliersUrl: string =
    environment.ripple_iot_auth_baseUrl + 'supplier/create-supplier';
  static updateSuppliersUrl: string =
    environment.ripple_iot_auth_baseUrl + 'supplier/edit-supplier/';
  static deleteSuppliersUrl: string =
    environment.ripple_iot_auth_baseUrl + 'supplier/delete-supplier/';

  //payloadConfig
  static getResponseConfigUrl: string =
    environment.ripple_iot_auth_baseUrl +
    'ResponseConfig/get-response-configurations';
  static createResponseConfigUrl: string =
    environment.ripple_iot_auth_baseUrl +
    'ResponseConfig/create-response-config';
  static getPayloadConfigUrl: string =
    environment.ripple_iot_auth_baseUrl + 'ResponseConfig/get-payload-config';

  static updatePayloadConfigUrl: string =
    environment.ripple_iot_auth_baseUrl +
    'ResponseConfig/update-response-config/';
  static deleteResponseConfigUrl: string =
    environment.ripple_iot_auth_baseUrl +
    'ResponseConfig/delete-response-config/';

  //Devices
  static getDevicesUrl: string =
    environment.ripple_iot_auth_baseUrl + 'Device/get-iot-devices';
  static createDevicesUrl: string =
    environment.ripple_iot_auth_baseUrl + 'Device/add-iot-device';
  static updateDevicesUrl: string =
    environment.ripple_iot_auth_baseUrl + 'Device/update-iot-device/';
  static deleteDevicesUrl: string =
    environment.ripple_iot_auth_baseUrl + 'Device/delete-iot-device/';

  //Readings
  static getReadingsUrl: string =
    environment.ripple_iot_auth_baseUrl + 'Readings/iot';
  static getArchicedUrl: string =
    environment.ripple_iot_auth_baseUrl + 'Readings/archived';
  static getRawDataUrl: string =
    environment.ripple_iot_auth_baseUrl + 'Readings/raw-data';
  static electricHistory: string =
    environment.ripple_iot_auth_baseUrl + 'Readings/electricity-status';
  static Eb_Dg_History: string =
    environment.ripple_iot_auth_baseUrl + 'Readings/electricity-history'; 
    static getRawDataById: string =
    environment.ripple_iot_auth_baseUrl + '/Readings/raw-data-by-id/';


    static getIotDataById: string =
    environment.ripple_iot_auth_baseUrl + '/Readings/iot-data-by-id/';

    


}
