import { UploadsModule } from '@progress/kendo-angular-upload';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedService } from './shared.service';
import { RpAlertsComponent } from './components/rp-alerts/rp-alerts.component';
import { RpLoaderComponent } from './components/rp-loader/rp-loader.component';
// import { StoreModule } from '@ngrx/store';
import { rootReducer } from '../store';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgSelectModule } from '@ng-select/ng-select';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { FilterPipe } from '../core/pipes/filter/filter.pipe';
import { RpBulkUpoadComponent } from './components/rp-bulkupload/rp-bulkupload.component';
import {
  GridModule,
  PDFModule,
  ExcelModule,
} from '@progress/kendo-angular-grid';
//import { RpBreadcrumbComponent } from './components/rp-breadcrumb/rp-breadcrumb.component';
//import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
@NgModule({
  declarations: [RpLoaderComponent, RpBulkUpoadComponent],
  imports: [
    CommonModule,
    // StoreModule.forRoot(rootReducer),
    BsDatepickerModule,
    BsDatepickerModule,
    TimepickerModule,
    BsDropdownModule,
    NgSelectModule,
    ModalModule.forRoot(),
    ReactiveFormsModule,
    FormsModule,
    DateInputsModule,
    LabelModule,
    UploadsModule,
    TooltipModule,
    GridModule,
    PDFModule,
    ExcelModule,
  ],
  exports: [
    RpLoaderComponent,
    ReactiveFormsModule,
    DateInputsModule,
    FormsModule,
    TooltipModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [SharedService],
    };
  }
}
