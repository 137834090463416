export enum SweetAlertIcon {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
  QUESTION = 'question',
}

export enum Roles {
  ADMIN = 'Admin',
  SUPERADMIN = 'SuperAdmin',
  CONSUMER = 'Consumer',
  AGENT = 'Agent',
}
